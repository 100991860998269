import * as ActionType from "./subCategory.type";

const initialState = {
  subCategory: [],
  categoryWiseSubCategory: [],
  categoryWiseServiceSubCategory: [],
};

export const subCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload,
      };
    case ActionType.GET_CATEGORY_WISE_SUBCATEGORY:
      return {
        ...state,
        categoryWiseSubCategory: action.payload,
      };
    case ActionType.CREATE_SUB_CATEGORY:


      return {
        ...state,
        // Ensure `categoryWiseSubCategory` is initialized as an array
        categoryWiseSubCategory: Array.isArray(state.categoryWiseSubCategory)
          ? Array.isArray(action?.payload)
            ? [...state.categoryWiseSubCategory, ...action?.payload] // If payload is an array
            : [...state.categoryWiseSubCategory, action?.payload] // If payload is an object
          : Array.isArray(action?.payload)
          ? [...action?.payload] // If first data and payload is array
          : [action?.payload], // If first data and payload is object
      };

    case ActionType.UPDATE_SUB_CATEGORY:
      const transformedData2 = {
        subCategoryId: action.payload.data._id,
        name: action.payload.data.name,
        image: action.payload.data.image,
        category: action.payload.data.category.name,
        categoryId: action.payload.data.category._id,
        sameSubcategoryProductCount: 0,
      };



      return {
        ...state,
        categoryWiseSubCategory: state.categoryWiseSubCategory.map((data) =>
          data?.subCategoryId
            ? data.subCategoryId === action.payload.id
              ? action?.payload?.data
              : data
            : data?._id === action?.payload?.id
            ? action?.payload?.data
            : data
        ),
      };
    case ActionType.DELETE_SUB_CATEGORY:
    case ActionType.DELETE_SUB_CATEGORY:
      return {
        ...state,
        categoryWiseSubCategory: state.categoryWiseSubCategory.filter((data) =>
          data?._id
            ? data._id !== action.payload
            : data?.subCategoryId !== action.payload
        ),
      };

    case ActionType.GET_CATEGORY_WISE_SERVICE_SUBCATEGORY:
      return {
        ...state,
        categoryWiseServiceSubCategory: action.payload,
      };

    case ActionType.CREATE_SUB_SERVICE_CATEGORY:
      const transformedDataService = {
        subCategoryId: action?.payload?._id,
        name: action?.payload?.name,
        image: action?.payload?.image,
        category: action?.payload?.category?.name,
        categoryId: action?.payload?.category?._id,
        sameSubcategoryProductCount: 0,
      };

      return {
        ...state,
        categoryWiseServiceSubCategory: [
          ...state.categoryWiseServiceSubCategory,
          action?.payload,
        ],
      };

    case ActionType.UPDATE_SUB_SERVICE_CATEGORY:
      return {
        ...state,
        categoryWiseServiceSubCategory:
          state.categoryWiseServiceSubCategory.map((data) =>
            data._id === action.payload.id ? action?.payload?.data : data
          ),
      };

    case ActionType.DELETE_SUB_SERVICE_CATEGORY:
      return {
        ...state,
        categoryWiseServiceSubCategory:
          state.categoryWiseServiceSubCategory.filter((data) =>
            data?._id
              ? data._id !== action.payload
              : data?.subCategoryId !== action.payload
          ),
      };

    default:
      return state;
  }
};
